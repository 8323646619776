import dayjs from "dayjs";
import "dayjs/locale/ko";
dayjs.locale("ko");

const formCheck = ($form) => {
    if (!$form || !$form.length) return false;
    
    let chk = true;

    $form.find('input, select, textarea').each((i, v) => {
        if (v.value === '' && $(v).data('optional') !== "skip") {
            alert($(v).data('check'));
            $(v).focus();
            chk = false;
            return false;
        }
    });

    return chk;
};

// 객실예약
$('.step-wrapper .menu-wrap .menu').click((e) =>{
    $(e.currentTarget).find('ul').toggle();
})


$('.step1-wrap .btn').click((e) =>{
    const $form = $('#step1form')
    const chk = formCheck($form)

    if(chk == true) {
        $form.submit();
    }
})

// 이용권안내
$('.step2-wrap .Membership-wrap .num').click((e) =>{
    $('.step-wrapper .popupMore').show()
})

$('.popupMore .btn-close').click((e) =>{
    $('.step-wrapper .popupMore').hide()
})

// 달력
$('.step2-wrap .btn-cal, .step6-wrap .btn-cal').click((e) =>{
    $('.step-wrapper .popupCal').css('top',0)
})

$('.popupCal .btn-close, .popupCal .btn-wrap').click((e) =>{
    $('.step-wrapper .popupCal').css('top','100%')
})
// 인원
$('.step2-wrap .btn-person').click((e) =>{
    $('.step-wrapper .popupPer').show()
})

$('.popupPer .btn-close, .popupPer .btn-wrap').click((e) =>{
    $('.step-wrapper .popupPer').hide()
})

$('.btn-modify').on('click', () => {
    const isEditing = $('.btn-modify').text() === "수정";
    $('#phone-input').prop('readonly', !isEditing).toggleClass('editing', isEditing);
    $('.btn-modify').text(isEditing ? "확인" : "수정").toggleClass('btn-editing', isEditing);

    if(!isEditing) {        
        alert(1);
        fetch('/reserve/mypage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': $('meta[name=csrf-token]').attr('content'),
            },
            body: JSON.stringify({
                tel:  $('#phone-input').val(),
            }),
        })
        .then((response) => response.json())
        .then((result) => {      
            console.log(result)          ;
            /* if(result.status == 200) {                
            } else {
                alert(result.msg)
            }    */
        })
    }
});


const formatCal = ((startDate, mode) => {
    const formatDate = (date) => mode ? dayjs(date).locale('ko').format('MM.DD (ddd)') : dayjs(date).locale('ko').format('MM.DD');
    // const nights = dayjs(endDate).diff(dayjs(startDate), 'day');
    const endDate =  dayjs(startDate).add(1, 'day')
    $('.cal_result').text(`${formatDate(startDate)} ~ ${formatDate(endDate)} / 1박`);
})

flatpickr(".showcal", {
    minDate: new Date(),
    defaultDate: new Date(),
    disableMobile: "true",
    locale: "ko",
    inline: true,
    showMonths: 2,
    onReady: (() =>{
        formatCal(dayjs(), dayjs().add(1, 'day'), 'w')
    }),
    onValueUpdate: ((selectedDate) =>{
        formatCal(selectedDate, 'w')
    })
});

window.onresize = function(event){
  var innerWidth = window.innerWidth;
  if(innerWidth <= "768"){
    flatpickr(".showcal", {
        minDate: new Date(),
        defaultDate: new Date(),
        locale: "ko",
        inline: true,
        showMonths: 1,
        onReady: (() =>{
            formatCal(dayjs(), dayjs().add(1, 'day'))
        }),
        onValueUpdate: ((selectedDate)=>{
            formatCal(selectedDate)
        })
    });
  }
}

const updateValue = (step, cur) => {
    let input = $(cur).siblings('.numInput');
    let value = Math.max(0, +input.val() + step);
    input.val(value);
    $('.per_result').text(value);
};

$('.btn-decrement').click((e) => updateValue(-1, $(e.currentTarget)));
$('.btn-increment').click((e) => updateValue(1, $(e.currentTarget)));

// 예약취소 
$('.step-wrapper .btn-cancel').click(() => {
    const str = {
        chkin: document.getElementById('chkin').innerText,
        room: document.getElementById('room-name').innerText,
        no: document.getElementById('no').value,
    }

    let msg = `일자 : ${str.chkin}\n객실 : ${str.room}\n\n예약을 취소하시겠습니까?`;


    if(confirm(msg) == true) {
        fetch("/reserve/mypage/reservation/cancel", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-TOKEN": $("meta[name=csrf-token]").attr("content"),
            },
            body: JSON.stringify({                    
                num:  str.no,
            }),
        })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);

            if(data.res == 200) {
                alert("예약건이 취소되었습니다.");
                location.href = `/reserve/mypage/reservation/${data.no}`;            }
        })
    }

});

$('.step5-wrap .ticket').click((e) => {
    $(e.currentTarget).next().toggleClass('active')
})

var ticketSwipers = [];
                
$('.step5-wrap .swiper-container').each(function(i) {
    var r_i = $(this).attr('id');
    
    ticketSwipers[i] = new Swiper('#'+r_i, {	
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
            el: '.swiper-pagination.'+r_i,
        },    
        breakpoints: {
            768: {
                slidesPerView: 4,
            }
        }
    })
})

$(window).resize(function() {
    if ($(window).width() <= 768) {
        $('.possession .cuts').each(function() {  
            let currentCutCount = $(this).find('.cut').length;
            let cutsToAdd = 12 - currentCutCount;

            if (cutsToAdd > 0) { 
                for (let i = 0; i < Math.min(cutsToAdd, 7); i++) { 
                    $(this).append('<div class="cut"></div>');
                }
            }
        });
    } else {
        $('.possession .cuts').each(function() {
            $(this).find('.cut').remove();
            for (let i = 0; i < 5; i++) {
                $(this).append('<div class="cut"></div>');
            }
        });
    }
}).resize(); 

$('.popupfct .selectBox').click((e) => {
    $(e.currentTarget).next().toggleClass('active')
})

let optchk
$('.popupfct .option li').click((e) => {
    let fctVal = $(e.currentTarget).text()
    let fct = $(e.currentTarget).data('num')
    $('.fctVal').text(fctVal)
    $('#fct').val(fct)
    $('.popupfct .option').removeClass('active')
    $('.popupfct.step1 .btn-use').addClass('active')
    optchk = true;
})

const optionReset = () => {
    $('.fctVal').text('수량 선택')
    $('#fct').val('')
    $('.popupfct.step1 .btn-use').removeClass('active')
    optchk = false;
}

$('.popupfct .btn-close').click((e) => {
    $('.popupfct').hide()
    $('body').removeClass('on')
    optionReset()
})
$('.possession .open-popupfct ').click((e) => {
    if (/Mobi|Android/i.test(navigator.userAgent)) {
        $('.popupfct.step1').show()
        $('body').addClass('on')
    }else{
        alert("이용권 현장사용을 위해  모바일(휴대폰)로 접속해주세요.");
    }
})
$('.popupfct.step1 .btn-use').click((e) => {
    if(optchk){
        $('.popupfct.step2').show()
    }
})
$('.popupfct.step2 .btn-use').click((e) => {
    $('.popupfct.step3').show()
})

const roomurl = 'https://img.iparksuite-sokcho.com/room/'
const roomData = {
    "파셜오션뷰디럭스더블": { 
        title: ["파셜오션뷰디럭스더블"],
        image: ["Deluxe_Double/"],
        length: [7]
    },
    "파셜오션뷰디럭스더블트윈": { 
        title: ["파셜오션뷰디럭스더블트윈", "파셜오션뷰디럭스더블온돌"],
        image: ["Deluxe_DoubleTwin/", "Deluxe_Ondol/"],
        length: [8, 9]
    },
    "파노라마오션뷰스위트패밀리": { 
        title: ["파노라마오션뷰스위트패밀리"],
        image: [ "Suite_Family/"],
        length: [6]
    },
}

var popupImgThumbsSwiper
var popupImgSwiper

const initPopupswiper = () => {
    popupImgThumbsSwiper = new Swiper('.popupImg .swiper .thumbs', {
        loop: true,
        spaceBetween: 10,
        slidesPerView: 5,
        freeMode: true,
        watchSlidesProgress: true,
        breakpoints: {
            768: {
                slidesPerView: 10,
            },
        },
    });
    
    popupImgSwiper = new Swiper('.popupImg .swiper .main', {
        loop: true,
        pagination: {
            el: ".swiper-pagination.main",
            type: "fraction",
          },
        navigation: {
          nextEl: '.swiper-button-next.main',
          prevEl: '.swiper-button-prev.main',
        },
        thumbs: {
            swiper: popupImgThumbsSwiper,
        },
        on: {
            init: function() {
                // Swiper가 완전히 초기화된 후 popupImg 요소를 보여줌
                $('.popupImg').show();
            },
            slideChange: function () {
                $('.popupImg .title-wrap .title').each((idx, el) => {
                    if($(el).attr('data-slideNum') <= this.realIndex){
                        $('.popupImg .title-wrap .title').removeClass('active')
                        $(el).addClass('active')
                    }
                })
            }
        }
    });
}

// $('.step6-wrap .list-wrap li').each(function() {
//     // 해당 li 내의 input 값 가져오기 (상품 이름)
//     let roomName = $(e.currentTarget).find('input').val();
    
//     // 리스트 img 수정
//     if (roomData[roomName]) {
//         $(this).find('img').attr('src', roomurl + roomData[roomName].image[0] + '01.jpg');
//     }
// });

$('.step6-wrap .btn-img').click((e) => {
    let roomName = $(e.currentTarget).parents('li').find('input').val();
    
    // 팝업 img, title 수정
    if (roomData[roomName]) {
        let curRoom =  roomData[roomName]
        curRoom.image.forEach(function(img, idx) {
            $('.popupImg .title-wrap').append(
                `<div class="title ${idx ? '' : 'active'}" data-slideNum="${curRoom.length.slice(0, idx).reduce((acc, val) => acc + val, 0)}">
                    ${curRoom.title[idx]}
                </div>`
            );
            for (let i = 1; i <= curRoom.length[idx]; i++) {
                let imgUrl = roomurl + img + (i < 10 ? '0' + i : i) + ".jpg";
                $('.popupImg .swiper-wrapper').append(
                    `<div class="swiper-slide">
                        <img src="${imgUrl}" alt="${curRoom.title[idx]}" class="img">
                    </div>`
                );
            }
        })
    }
    // html 추가후 스와이퍼 초기화
    initPopupswiper();
})

$('.popupImg .btn-close').click((e) => {
    $('.popupImg .title-wrap').empty();
    $('.popupImg .swiper-wrapper').empty();
    $('.popupImg').hide()
})

$('.popupImg .title-wrap').on('click','.title', (e) => {
    let slideNum = $(e.currentTarget).attr('data-slideNum')
    
    // 탭 스와이퍼 이동
    popupImgSwiper.slideTo(slideNum, 0, false)
    $('.popupImg .title-wrap .title').removeClass('active');
    $(e.currentTarget).addClass('active');
    
})

$('.step7-wrap .agree .info').click((e) => [
    $('.check-list').toggle()
])

$('.step7-wrap .option').change((e) => {
    $(e.currentTarget).siblings('.sub-box').toggle()
})

$('.step7-wrap .pay-wrap li').click((e) => {
    let paytype = $(e.currentTarget).data('type')

    $('.step7-wrap #paytype').val(paytype)

    $('.step7-wrap .pay-wrap li').removeClass('active')
    $(e.currentTarget).addClass('active')
})

$('.step7-wrap .btn-bnf').click((e) => {
    $('.popupBnf').show()
})
$('.popupBnf .btn-close').click((e) => {
    $('.popupBnf').hide()
})

$('.step7-wrap #check_all').change((e) => {
    if($(e.currentTarget).is(':checked')){
        $('.check-list input:checkbox').val('y')
    }
})

$('.step7-wrap input[type=checkbox]').change((e) => {
    $(e.currentTarget).is(':checked') ? $(e.currentTarget).val('y') : $(e.currentTarget).val('')
})
$('.step7-wrap .recipt-wrap .btn').click((e) => {
    const $form = $('#rsvform')
    const chk = formCheck($form)

    if(chk == true) {
        alert('완료')
        // $form.submit();
    }
}) 